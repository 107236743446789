<script setup lang="ts">
import { ref, watch } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import { useEcomStore } from '@/stores/apps/eCommerce';
import { IconShoppingCart, IconScan, IconMenu2, IconGridDots } from '@tabler/icons-vue';
// Icon Imports
const customizer = useCustomizerStore();
const showSearch = ref(false);
const drawer = ref(false);
const appsdrawer = ref(false);
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
function searchbox() {
    showSearch.value = !showSearch.value;
}
watch(priority, (newPriority) => {
    // yes, console.log() is a side effect
    priority.value = newPriority;
});
// count items
const store = useEcomStore();
const getCart = computed(() => {
    return store.cart;
});
</script>

<template>

    <v-app-bar elevation="10" :priority="priority" height="70">
        <div :class="customizer.boxed ? 'maxWidth v-toolbar__content' : 'v-toolbar__content px-6'">

            <v-locale-provider>
                <div class="hidden-sm-and-down mr-2">
                    <LayoutFullLogo />
                </div>
                <div class="hidden-sm-and-up mr-2 pa-1">
                    <LayoutFullLogoMini />
                </div>
            </v-locale-provider>

            <!-- <v-btn class="hidden-lg-and-up ms-3" icon rounded="sm" variant="flat"
                @click.stop="customizer.SET_SIDEBAR_DRAWER" size="small">
                <IconMenu2 size="20" stroke-width="1.5" />
            </v-btn> -->

            <!-- search mobile -->
            <!-- <v-btn class="hidden-lg-and-up ml-3" icon rounded="sm" variant="flat" size="small" @click="searchbox">
                <SearchIcon size="17" stroke-width="1.5" />
            </v-btn> -->

            <!-- <v-sheet v-if="showSearch" class="search-sheet v-col-12">
                <LayoutFullVerticalHeaderSearchbar :closesearch="searchbox" />
            </v-sheet> -->

            <!-- ------------------------------------------------>
            <!-- Search part -->
            <!-- ------------------------------------------------>
            <!-- <v-sheet>
                <LayoutFullVerticalHeaderSearchbar />
            </v-sheet> -->

            <!---/Search part -->

            <!-- ------------------------------------------------>
            <!-- Mega menu -->
            <!-- ------------------------------------------------>
            <!-- <div class="hidden-md-and-down">
                <LayoutFullVerticalHeaderNavigations />
            </div> -->

            <v-spacer />
            <!-- ---------------------------------------------- -->
            <!---right part -->
            <!-- ---------------------------------------------- -->

            <!-- ---------------------------------------------- -->
            <!-- translate -->
            <!-- ---------------------------------------------- -->
            <!-- <LayoutFullVerticalHeaderLanguageDD /> -->

            <LayoutFullVerticalHeaderStoreDD />

            <!-- ---------------------------------------------- -->
            <!-- ShoppingCart -->
            <!-- ---------------------------------------------- -->

            <!-- <v-btn icon variant="text" @click="navigateTo({ path: '/', query: { showReader: 'true' }})">
                <IconScan stroke-width="1.5" size="28" />
            </v-btn> -->
            
            <v-btn icon variant="text" v-if="getCart?.length > 0" color="primary" to="/apps/ecommerce/checkout" class="mr-2">
                <v-badge color="primary" :content="useEcomStore().getTotalQty">
                    <IconShoppingCart stroke-width="1.5" size="26" />
                </v-badge>
            </v-btn>

            <!-- ---------------------------------------------- -->
            <!-- Notification -->
            <!-- ---------------------------------------------- -->

            <!-- <LayoutFullVerticalHeaderNotificationDD /> -->



            <!-- right sidebar -->
            <v-btn
                v-if="useAuthStore().getUserGroups.includes('Admin')"
                class="ml-3" icon rounded="sm" 
                @click.stop="appsdrawer = !appsdrawer"
                variant="flat">
                <IconGridDots size="17" stroke-width="1.5" />
            </v-btn>

            <!-- ---------------------------------------------- -->
            <!-- User Profile -->
            <!-- ---------------------------------------------- -->
            <div class="mr-2 mr-sm-0 pr-2">
                <LayoutFullVerticalHeaderProfileDD />
            </div>
        </div>
    </v-app-bar>
    <v-navigation-drawer v-model="appsdrawer" location="right" temporary>
        <LayoutFullVerticalHeaderRightMobileSidebar />
    </v-navigation-drawer>
</template>
