<script lang="ts" setup>
import { getDistance } from 'geolib';
import { useAuthStore } from '@/stores/auth';
import { IconMapPin, IconChevronDown, IconRefresh, IconMapPinSearch, IconMapPinCheck, IconMapPinPlus, IconHandStop, IconMapPinExclamation, IconMapPinPin } from '@tabler/icons-vue';
import SnackBar from '@/components/ui-components/alert/SnackBar.vue';

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const auth = useAuthStore()
const stores = useStoresStore()
const snackAlertMessage = ref({
    message: '',
    type: '',
    show: false
})

const storeIsNotSet = ref(false)
const tempStore = ref({ name: null, city: null});
const userCoordinates = ref()
const loadingProximity = ref(false);
const selectStoreMessage = ref('');
const establishments = ref(stores.stores.data);
const promptChangeStore = ref(false);
const checkedProximity = ref(false);
const nearbyStores = ref(await stores.getNearbyStores)
const selectedStore = ref();

const getUserCoordinates = computed(() => stores.getUserCoordinates );


const validateSelectClosestStore = async (from?:string|null) => {
    nearbyStores.value = await stores.getNearbyStores;
    const closestDistance = 300;
    // console.info("entra validateSelectClosestStore... from: ", from)
    // console.info("nearbyStores.value.length: ", nearbyStores.value.length)
    // if (stores.selectedStore.city) return;
    if (nearbyStores.value.length === 1){
        // console.log("entra 1:")
        stores.setSelectedStore(nearbyStores.value[0])
        snackAlertMessage.value = { show: true, type: 'success', message: 'Ubicación seleccionada'};
        storeIsNotSet.value = false;
    } else if (nearbyStores.value.filter((store:any) => store.currentDistance <= closestDistance).length === 1 && !from){
        // console.info("hay cercanos...", nearbyStores.value.filter((store:any) => store.currentDistance <= 50).length)
        // console.log("entra 2:")
        const closestStore = nearbyStores.value.filter((store:any) => store.currentDistance <= closestDistance)[0];
        if (closestStore){
            if ((selectedStore.value && selectedStore.value.id != closestStore.id) || !selectedStore.value){
                stores.setSelectedStore(closestStore)
                snackAlertMessage.value = { show: true, type: 'success', message: `${t('login.welcome')} ${t('basic.to')} ${closestStore.name}`};
                storeIsNotSet.value = false;
            }  else if (selectedStore.value && !from){
                storeIsNotSet.value = false;
            }
        }
    } /* else {
        storeIsNotSet.value = true;
    }*/
    // console.log("storeIsNotSet.value:", storeIsNotSet.value)
}

const successPosition = async (position: GeolocationPosition, from?:string|null) => {
    console.info("successPosition ok", position)
    stores.setUserCoordinates({lat: position.coords.latitude, lon: position.coords.longitude})
    await positionHandler(position.coords.latitude, position.coords.longitude, from);
}

const positionHandler = async (latitude:any, longitude:any, from?:string|null) => {
    // console.log("positionHandler ok, lat: " + latitude + ", lon: " + longitude, from);
    userCoordinates.value = `${latitude},${longitude}`
    try {
        establishments.value = await stores.refreshNearbyStores({ 
                                    lat: latitude, 
                                    lon: longitude })
    } catch (error) {
        snackAlertMessage.value = { show: true, type: 'warning', message: `Sorry, tenemos inconvenientes, intenta en unos segundos`};
    }

    const userLocation = {
        latitude: latitude,
        longitude: longitude
    };

    if (establishments.value && establishments.value.length > 0){
        selectStoreMessage.value = '';
        // console.log("into PH 1: ")
        const isCompanyUser = auth.isCompanyUser;
        await establishments.value.forEach(async (_store:any) => {
            if (isCompanyUser){
                _store.isNearby = true;
                _store.currentDistance = null;
            } else {
                const store_location = _store.location.split(',')
                const distance = await getDistance(userLocation, { 
                                                    latitude: store_location[0], 
                                                    longitude: store_location[1]
                                                });
                if (distance <= 1500){
                    // console.info("Nearby store found: " + _store)
                    _store.isNearby = true;
                } else {
                    _store.isNearby = false;
                }
                _store.currentDistance = distance;
            }
        });
        // console.log("into PH 2: ")
        await stores.setNearbyStores(establishments.value);
        // console.log("into PH 3: ")
        await validateSelectClosestStore(from);
        loadingProximity.value = false;
    } else {
        // console.log("into PH 4 (else): ")
        stores.setNearbyStores([]);
        stores.setSelectedStore({})
        selectStoreMessage.value += t('app.messages.notNearbyStoresFound')
        loadingProximity.value = false;
    }
}

const errorPosition = (error: GeolocationPositionError) => {
    loadingProximity.value = false;
    console.error(error);
    if (error.code === 1){
        selectStoreMessage.value += `<h3>ERROR:</h3><br><b>${ t('basic.Instructions')}:</b><br><ul><li><b>iPhone:</b> ${ t('errors.gpsErrors.instructionsIphone') }</li></ul>`;
    } else {
        selectStoreMessage.value += `<br>ERROR: [${error.code}] ${error.message ?? error.code === 2 ? 'PERMISO DENEGADO' : 'OTRO'}`;
    }
    console.error(`Error retrieving user location: ${error.code}:${error.message}`);
}

const checkProximity = async (from?:string|null) => {
    console.log("into checkProximity")
    checkedProximity.value = true;
    // console.log(navigator)
    if ('geolocation' in navigator && navigator.geolocation) {
        // console.info("navigator.geolocation ok")
        loadingProximity.value = true;
        selectStoreMessage.value = '';
        try {
            await navigator.geolocation.getCurrentPosition(
                async (position) => successPosition(position, from), 
                                        errorPosition, 
                                        {
                                            enableHighAccuracy: true,
                                            timeout: 5000,
                                            maximumAge: 0
                                        });
        } catch(error) {
            console.error(`Error retrieving user location:`, error);
        }
    } else {
        selectStoreMessage.value += `<br>ERROR: Geolocation is not supported by this browser.`;
        console.error('Geolocation is not supported by this browser.');
    }
};

function changeStore(answer:boolean){
    if(answer && tempStore.value.id){
        stores.setSelectedStore(tempStore.value);
        snackAlertMessage.value = { show: true, type: 'success', message: 'Ubicación detectada'};
        nearbyStores.value.forEach((store:any) => {
            if (store.id === tempStore.value.id){
                stores.selectedStore = store;
                useEcomStore().deleteCart("LocationHandler");
            }
        });
        promptChangeStore.value = false;
    } else {
        promptChangeStore.value = false; 
        tempStore.value = { id: null, name: null }; 
    }
     // reset tempStore for next use.  This is important to avoid memory leaks.  Otherwise, the component will keep holding onto the old reference.  This could cause unexpected behavior if the store object changes.  In this case, we are just resetting it to null.  In a real-world scenario, you would need to update the reference to the new store object.  For example, if the store object is a reactive object, you might update it using Vue's set() function.  But for simplicity, I've chosen to just reset it.  In a production application, you would want to handle this more gracefully.  For example, you could use Vue's watch to detect changes in the store object and update the reference automatically.  But again, for simplicity, I've chosen to avoid this for this example
}

onBeforeMount(async () => {
    selectedStore.value = stores.getSelectedStore
    if (!selectedStore.value){ 
        storeIsNotSet.value = true;
        await positionHandler(getUserCoordinates.value.lat, getUserCoordinates.value.lon);
    } else {
        storeIsNotSet.value = false;
        await positionHandler(getUserCoordinates.value.lat, getUserCoordinates.value.lon);
    }
});

</script>
<template>
    <v-menu :close-on-content-click="true" location="bottom">
        <template v-slot:activator="{ props }">
            <v-btn variant="text" v-bind="props" @click="async () => { checkProximity('menu'); storeIsNotSet=true }" icon class="mr-2">
                <IconMapPinExclamation v-if="!selectedStore" stroke-width="1.8" size="28" color="red" /> 
                <IconMapPinCheck v-else stroke-width="1.8" size="28" /> 
                <!-- {{ selectedStore.name ?? 'Selecciona una tienda' }} -->
                <!-- <IconChevronDown stroke-width="1.5" size="17" class="ml-1" /> -->
            </v-btn>
        </template>
    </v-menu>
    <v-dialog v-model="storeIsNotSet">
        <v-card>
            <template v-slot:append>
                <v-btn icon="$close" variant="text" color="primary" @click="storeIsNotSet = false"></v-btn>
            </template>
            <template v-slot:title>
                <h3 class="text-h3 text-primary"><IconMapPin size="28" class="text-primary" style="margin-bottom: -5px" />  {{ $t('basic.where_are_you') }}</h3>
            </template>
            <template v-slot:text>
                <p class="text-center">
                    <span v-if="selectedStore">
                        {{ $t('current_location') }}<br>
                        <span class="text-h5">{{ selectedStore.name }}</span>
                    </span>
                    <span v-else>{{ $t('basic.select2')}}:</span>
                </p>
                <div v-if="nearbyStores.length > 0 && !loadingProximity && checkedProximity" class="d-flex" >
                    <VSelect v-model="tempStore" 
                        name="lang" 
                        density="compact" 
                        variant="outlined"
                        class="py-4 px-1"
                        persistent-hint
                        :hint="loadingProximity ? t('basic.loading') : tempStore.name ? `${tempStore.name} (${tempStore.city})` : 'Selecciona la tienda en donde te encuentras'"
                        return-object
                        item-title="name"
                        no-data-text="No tienes tiendas cerca de tí"
                        :items="nearbyStores"
                        >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="!item.raw.isNearby" style="color: rgb(var(--v-theme-on-surface-variant))">
                                <v-list-item-subtitle >
                                    {{ item.raw.address }} 
                                    <span v-if="item.raw.currentDistance >= 0">({{ item.raw.currentDistance < 1000 ? item.raw.currentDistance+'mts' : new Intl.NumberFormat('es-CO').format((item.raw.currentDistance/1000).toFixed(1))+'km' }})</span>
                                    <IconMapPin v-if="item.raw.currentDistance <= 500" size="14" class="text-primary ml-2" />
                                </v-list-item-subtitle>
                            </v-list-item>
                        </template>
                    </VSelect>
                    <v-btn v-if="!tempStore.name" @click="checkProximity('button')" :disabled="loadingProximity" icon variant="plain" class="mt-2" >
                        <IconRefresh size="24" />
                    </v-btn>
                </div>
                <div v-else-if="loadingProximity" class="d-flex align-center w-100 text-center justify-center text-primary py-4">
                    <IconHandStop size="24" class="text-primary mr-2" /> {{$t('basic.pleaseWait')}}
                </div>
                <v-alert v-if="selectStoreMessage !== ''" type="error" variant="tonal" class="mt-3 pa-1 text-subtitle-1">
                    <p v-html="selectStoreMessage"></p>
                </v-alert>
            </template>
            <template v-slot:actions>
                <div class="d-flex align-center w-100 text-center justify-center">
                    <v-btn v-if="tempStore.name" @click="stores.setSelectedStore(tempStore); storeIsNotSet = false;" color="primary" variant="tonal" rounded >
                        <IconMapPinPlus stroke-width="1.8" size="18" class="mr-1" />  {{ $t('basic.select')}}
                    </v-btn>
                </div>
            </template>
        </v-card>
    </v-dialog>
    <v-dialog v-model="promptChangeStore" max-width="400"persistent >
        <v-card v-if="tempStore.id" >
            <template v-slot:append>
                <v-btn icon="$close" variant="text" color="primary" @click="promptChangeStore = false"></v-btn>
            </template>
            <template v-slot:title>
                <IconMapPin size="24" class="text-primary" /> <span class="text-h3 text-primary">Cambio de tienda</span>
            </template>
            <template v-slot:text>
                <div class="pa-2">
                    ¿Estás seguro de cambiar de tienda?<br><br>
                    <p class="text-center">
                        <b>{{ selectedStore && selectedStore.name }}</b> <br />
                        a<br />
                        <b>{{ tempStore.name }}</b><br />
                    </p>
                    <br />
                    <p class="h6 text-center">
                        ¡Vas a perder tus productos del carrito!<br/>
                        Recuerda que debes estar presente en esta tienda para llevar tus productos.
                    </p>
                </div>
            </template>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="changeStore(false);">Cancelar</v-btn>
                <v-btn color="error" @click="changeStore(true)">Cambiar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <SnackBar :alert="snackAlertMessage" :opacity="0.5" timeout="1500" />
</template>

<style>

</style>