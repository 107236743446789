<script setup lang="ts">
import { ref } from 'vue';
import { IconApps, IconCalendar, IconMail, IconMessages } from '@tabler/icons-vue';
const open = ref(['Apps']);
</script>

<template>
    <div class="pt-6">
        <h5 class="text-h5 mb-4 px-5">Administración</h5>
        <v-list v-model:opened="open" class="">
            <v-list-group value="Apps">
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props">
                        <template v-slot:prepend>
                            <IconApps width="21" stroke-width="1.5" />
                        </template>
                        <h5 class="text-subtitle-1 ml-2">Aplicaciones</h5>
                    </v-list-item>
                </template>
                <v-list-item class="pl-6 pb-6">
                    <div>
                        <LayoutFullVerticalHeaderAppsLink />
                    </div>
                </v-list-item>
            </v-list-group>
            <!-- <v-list-item>
                <template v-slot:prepend>
                    <IconMessages width="21" stroke-width="1.5" />
                </template>
                <h5 class="text-subtitle-1 ml-2">Chats</h5>
            </v-list-item>
            <v-list-item>
                <template v-slot:prepend>
                    <IconCalendar width="21" stroke-width="1.5" />
                </template>
                <h5 class="text-subtitle-1 ml-2">Calendar</h5>
            </v-list-item>
            <v-list-item>
                <template v-slot:prepend>
                    <IconMail width="21" stroke-width="1.5" />
                </template>
                <h5 class="text-subtitle-1 ml-2">Mail</h5>
            </v-list-item> -->
        </v-list>
        <!-- <div class="px-5">
            <h5 class="text-h5 my-4">Quick Links</h5>
            <LayoutFullVerticalHeaderQuickLinks />
        </div> -->
    </div>
</template>

<style scoped>
.v-list-group__items .v-list-item {
    padding-inline-start: 12px !important;
    margin-top: 12px;
}
</style>
