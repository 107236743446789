<script setup lang="ts">
import { ref, watch } from 'vue';
import { useCustomizerStore } from '@/stores/customizer';
import { useEcomStore } from '@/stores/apps/eCommerce';
const customizer = useCustomizerStore();
const showSearch = ref(false);
const drawer = ref(false);
const appsdrawer = ref(false);
const priority = ref(customizer.setHorizontalLayout ? 0 : 0);
function searchbox() {
    showSearch.value = !showSearch.value;
}
watch(priority, (newPriority) => {
    // yes, console.log() is a side effect
    priority.value = newPriority;
});
// count items
const store = useEcomStore();
const getCart = computed(() => {
    return store.cart;
});
</script>

<template>
    <v-app-bar elevation="0" :priority="priority" height="70" class="">
        <v-btn class="hidden-md-and-down custom-hover-primary" icon color="primary" variant="text"
            @click.stop="customizer.SET_MINI_SIDEBAR(!customizer.mini_sidebar)">
            <Menu2Icon size="20" stroke-width="1.5" />
        </v-btn>
        <v-btn class="hidden-lg-and-up " icon variant="flat" @click.stop="customizer.SET_SIDEBAR_DRAWER"
            size="small">
            <Menu2Icon size="20" stroke-width="1.5" />
        </v-btn>

        <!-- ---------------------------------------------- -->
        <!-- Search part -->
        <!-- ---------------------------------------------- -->
        <v-sheet class="">
            <LayoutFullVerticalHeaderSearchbar />
        </v-sheet>

        <!---/Search part -->

        <!-- ---------------------------------------------- -->
        <!-- Mega menu -->
        <!-- ---------------------------------------------- -->
        <div class="hidden-md-and-down">
            <LayoutFullVerticalHeaderNavigations />
        </div>
        <v-spacer />
        <!-- ---------------------------------------------- -->
        <!---right part -->
        <!-- ---------------------------------------------- -->
        <!-- ---------------------------------------------- -->
        <!-- translate -->
        <!-- ---------------------------------------------- -->
        <LayoutFullVerticalHeaderLanguageDD />

        <!-- ---------------------------------------------- -->
        <!-- ShoppingCart -->
        <!-- ---------------------------------------------- -->
        <v-btn icon variant="text" color="primary" v-if="useEcomStore().getCart.length > 0" to="/apps/ecommerce/checkout">
            <v-badge color="primary" :content="useEcomStore().getTotalQty">
                <!-- <ShoppingCartIcon stroke-width="1.5" size="22" /> -->
                <CashIcon stroke-width="1.5" size="22" />
            </v-badge>
        </v-btn>

        <!-- ------------------------------------------------>
        <!-- Notification -->
        <!-- ------------------------------------------------>
        <LayoutFullVerticalHeaderNotificationDD />


        <!-- right sidebar -->
        <v-btn 
            v-if="useAuthStore().getUserGroups.includes('Admin')"
            variant="text" color="primary" 
            class="custom-hover-primary" icon
            @click.stop="appsdrawer = !appsdrawer">
            <IconGridDots size="17" stroke-width="1.5" />
        </v-btn>

        <!-- ---------------------------------------------- -->
        <!-- User Profile -->
        <!-- ---------------------------------------------- -->
        <div class="ml-sm-2">
            <LayoutFullVerticalHeaderProfileDD />
        </div>
    </v-app-bar>


    <!-- ---------------------------------------------- -->
    <!-- Right Sidebar -->
    <!-- ---------------------------------------------- -->
    <v-navigation-drawer v-model="appsdrawer" location="right" temporary>
        <LayoutFullVerticalHeaderRightMobileSidebar />
    </v-navigation-drawer>
</template>
