<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
const primary = ref('rgb(var(--v-theme-primary))');
const secondary = ref('rgb(var(--v-theme-secondary))');
import LogoMini from '/public/images/logos/snapei-solo.svg'
</script>
<template>
    <div class="logo">
        <NuxtLink to="/" class="pa-2">
            <VImg :src="LogoMini" height="44" width="44" >
            </VImg>
        </NuxtLink>
    </div>
</template>

